import { Heading, HStack, Image, Text, VStack, Box } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import React from "react";

const Card = ({ title, description, imageSrc }) => {
  // Implement the UI for the Card component according to the instructions.
  // You should be able to implement the component with the elements imported above.
  // Feel free to import other UI components from Chakra UI if you wish to.
  return (
    <Box borderRadius='lg' overflow='hidden' bg='white' color='black'>
      <Image src={imageSrc} boxSize='100%' height='400px' objectFit="cover" />

      <Box display='flex' flexDirection='column' p={6}>
        <VStack align='left'>
          <Heading as='h4' size='md'>{title}</Heading>
          <Text fontSize='md' color='gray.500'>{description}</Text>
          
          <HStack>
            <Text as='p' fontSize='sm'>
              See more <FontAwesomeIcon icon={faArrowRight} size="1x" />
            </Text>
          </HStack>
        </VStack>
      </Box>
      
    </Box>
  );
};

export default Card;
